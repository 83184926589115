import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  panelContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: 0,
    color: theme.palette.primary.main,
  },
  iconDisaled: {
    marginLeft: theme.spacing(1),
    marginRight: 0,
    color: theme.palette.grey[400],
  },
  loginLink: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    [theme.breakpoints.up('sm')]: {
      borderRadius: '2px',
      border: '1px solid',
      borderColor: theme.palette.secondary.light,
      boxShadow: 'none',
    },
  },
  listItemDisaled: {
    [theme.breakpoints.up('sm')]: {
      borderRadius: '2px',
      border: '1px solid',
      borderColor: theme.palette.grey[600],
      boxShadow: 'none',
    },
  },
  listItemText: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      borderBottom: '1px solid',
      color: theme.palette.secondary.light,
      boxShadow: 'none',
    },
  },
  listItemTextDisaled: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
    color: theme.palette.grey[400],
    [theme.breakpoints.down('xs')]: {
      borderBottom: '1px solid',
      color: theme.palette.grey[400],
      boxShadow: 'none',
    },
  },
  xsButton: {
    color: `${theme.palette.common.white} !important`,
    paddingTop: `${theme.spacing(2)}px !important`,
    paddingBottom: `${theme.spacing(2)}px !important`,
    fontSize: `${theme.typography.fontSize * 1.5}px !important`,
    borderRadius: '2px',
    border: '1px solid',
    borderColor: theme.palette.secondary.light,
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '90%',
  },
  xsIcon: {
    color: theme.palette.primary.main,
    width: '10% !important',
    marginRight: '5%',
    marginLeft: '-10%',
    [theme.breakpoints.down(theme.breakpoints.values.xsm)]: {
      display: 'none',
    },
  },
  xsIconDisabled: {
    color: theme.palette.grey[400],
    width: '10% !important',
    marginRight: '5%',
    marginLeft: '-10%',
    [theme.breakpoints.down(theme.breakpoints.values.xsm)]: {
      display: 'none',
    },
  },
  xsMessage: {
    fontSize: theme.typography.fontSize * 1.15,
    marginTop: theme.spacing(1),
    width: '85%',
  },
  xsMessageDisabled: {
    color: theme.palette.grey[400],
    fontSize: theme.typography.fontSize * 1.15,
    marginTop: theme.spacing(1),
    width: '85%',
  },
});
