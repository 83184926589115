import React from 'react';
import loadable from '@loadable/component';
import {
  string,
  object,
  bool,
  func,
} from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckLoading } from '@fortawesome/pro-solid-svg-icons';

import useStyles from 'components/cta/styles';

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Hidden = loadable(() => import('@material-ui/core/Hidden'));
const Typography = loadable(() => import('@material-ui/core/Typography'));
const ListItem = loadable(() => import('@material-ui/core/ListItem'));
const ListItemIcon = loadable(() => import('@material-ui/core/ListItemIcon'));
const ListItemText = loadable(() => import('@material-ui/core/ListItemText'));
const Button = loadable(() => import('@material-ui/core/Button'));

const ActionCta = ({
  icon,
  action,
  primary,
  secondary,
  primarySmall,
  externalRoute = false,
  working = false,
}) => {
  const styles = useStyles();

  return (
          <Grid spacing={2}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
            <Hidden only={['xs']}>
              <Grid item>
              {working ? (
                <ListItem
                  className={styles.listItemDisaled}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faTruckLoading} size="2x" className={styles.iconDisaled}/>
                  </ListItemIcon>
                  <ListItemText
                    className={styles.listItemTextDisaled}
                    primary={
                      <Typography variant="h6" component="p">
                       {primary}
                      </Typography>
                      }
                      secondary={secondary}
                  />
                </ListItem>
              ) : (
                <ListItem
                  button
                  className={styles.listItem}
                  onClick={action}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={icon} size="2x" className={styles.icon}/>
                  </ListItemIcon>
                  <ListItemText
                    className={styles.listItemText}
                    primary={
                      <Typography variant="h6" component="p">
                       {primary}
                      </Typography>
                      }
                      secondary={secondary}
                  />
                </ListItem>
              )}
              </Grid>
            </Hidden>
            <Hidden only={['lg', 'md', 'sm', 'xl']}>
              <Button
                color="secondary"
                fullWidth
                variant="outlined"
                onClick={action}
                disabled={working}
                className={styles.xsButton}
                aria-label={primarySmall}
                >
                <FontAwesomeIcon
                  icon={working ? faTruckLoading : icon}
                  size="lg"
                  className={working ? styles.xsIconDisabled : styles.xsIcon}/>
                <Typography variant="h6" component="p" align="center" noWrap>
                 {primarySmall}
                </Typography>
              </Button>
              <Typography
                variant="caption"
                component="p"
                align="right"
                className={working ? styles.xsMessageDisabled : styles.xsMessage}>
               {secondary}
              </Typography>
            </Hidden>
          </Grid>
  );
};

ActionCta.propTypes = {
  icon: object.isRequired,
  action: func.isRequired,
  primary: string.isRequired,
  primarySmall: string.isRequired,
  secondary: string.isRequired,
  externalRoute: bool,
  working: bool,
};

export default ActionCta;
