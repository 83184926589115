import React, { useState } from 'react';
import { string } from 'prop-types';
import { SIGNUP_REDIRECT_URL } from 'apputil/auth-0';

import ActionCta from 'components/cta/action';
import { faSmilePlus } from '@fortawesome/pro-solid-svg-icons';

import { useAuth0 } from '@auth0/auth0-react';

const RegisterActionCta = ({
  primary,
  secondary,
  primarySmall,
}) => {
  const [working, setWorking] = useState(false);
  const { loginWithRedirect } = useAuth0();

  return (
    <ActionCta
        working={working}
        primary={primary}
        secondary={secondary}
        primarySmall={primarySmall}
        icon={faSmilePlus}
        action={async () => {
          setWorking(true);
          const buildDataLayer = await import('data-layer');
          const dataLayer = buildDataLayer.default();
          dataLayer.trackUser({
            descriptor: 'user_handle_register_start',
            label: primarySmall,
          });
          setTimeout(() => loginWithRedirect({
            prompt: 'select_account',
            screen_hint: 'signup',
            redirectUri: SIGNUP_REDIRECT_URL,
          }),
          100);
        }}/>
  );
};

RegisterActionCta.propTypes = {
  primary: string.isRequired,
  primarySmall: string.isRequired,
  secondary: string.isRequired,
};

export default RegisterActionCta;
